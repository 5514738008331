import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';

import styles from './Footer.module.scss';

import { elementClicked } from '@utils/sendEvent';
import { insuranceProviderUrl } from '@constants/constants';
import routes from '@constants/routes';
import JoinForm from '@components/Footer/JoinForm/JoinForm';
import FooterPartners from '@components/Footer/Partners/Partners';
import FooterSocials from '@components/Footer/Socials/FooterSocials';
import FooterDestinations from '@components/Footer/Destinations/FooterDestinations';

const Footer = ({ customerArea, isShowFooterJoinForm }) => (
  <div
    className={classnames(styles['app-footer'], {
      'app-footer--customer-area': customerArea
    })}
  >
    {isShowFooterJoinForm && <JoinForm />}
    <footer className={styles.footer}>
      <div className="app-page-container">
        {!customerArea && <FooterPartners />}
        <div className="row">
          <div className="col-40">
            <div className="footer__logo">
              <Image
                width={120}
                height={29}
                src="/img/logo-elsewhere-lp-gray.svg"
                alt="logo"
                className="elsewhere-footer-logo"
              />
              <FooterSocials />
            </div>
            <div className="footer__exclusive">
              <div className="footer__category">Our exclusive travel insurance provider.</div>
              <Button
                sx={{ px: 4, mt: 0.5 }}
                variant="outlined"
                size="medium"
                target="_blank"
                rel="noreferrer noopener"
                href={insuranceProviderUrl}
              >
                Wanderwell
              </Button>
            </div>
          </div>
          <div className="col-20 mt-mobile">
            <div className="footer__category">About</div>
            <ul className="footer__list" role="list">
              {/* <li>
                <Link href={routes.weAre}>
                  <a className="footer__nav">We are</a>
                </Link>
              </li> */}
              <li>
                <Link href={routes.howItWorks}>
                  <button
                    onClick={() => {
                      elementClicked({
                        actionOutcome: 'INTERNALLINK',
                        outboundUrl: 'null',
                        webElement: {
                          elementType: 'link',
                          location: 'homepage',
                          name: 'nav bar',
                          position: 'footer',
                          text: 'How it works'
                        }
                      });
                    }}
                    className={classnames(styles['footer_hiw'])}
                  >
                    How it works
                  </button>
                </Link>
              </li>
              <li>
                <Link href={routes.ourCommitments}>
                  <a className="footer__nav">Commitments</a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-40 mt-mobile">
            <div className="footer__category">Support</div>
            <ul className="footer__list" role="list">
              <li>
                <Link href={routes.contactUs}>
                  <a className="footer__nav">Contact Us</a>
                </Link>
              </li>
              <li>
                <Link href={routes.privacyPolicy}>
                  <a target="_blank" className="footer__nav">
                    Privacy Policy
                  </a>
                </Link>
              </li>
              <li>
                {/* ONE TRUST COOKIE BUTTON */}
                <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                  Cookie Settings
                </button>
              </li>
              <li>
                {/* ONE TRUST DO NOT SELL LINK */}
                <Link href={routes.doNotSell}>
                  <a target="_blank" rel="noopener noreferrer" className="footer__nav">
                    Do Not Sell or Share My Personal Information
                  </a>
                </Link>
              </li>
              <li>
                <Link href={routes.termsOfUse}>
                  <a target="_blank" className="footer__nav">
                    Terms of Use
                  </a>
                </Link>
              </li>
              <li>
                {/*  TODO: Uncomment when Local Expert Application is ready ticket: https://redventures.atlassian.net/browse/ELSEWHERE-1262
                <Link href={localExpertAppURL}>
                  <a className="footer__nav no-br-mobile" target="_blank" rel="noreferrer">
                    Local Expert Application
                  </a>
                </Link> 
                */}
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <FooterDestinations />
        </div>
      </div>
    </footer>
  </div>
);

Footer.defaultProps = {
  isShowFooterJoinForm: true
};

Footer.propTypes = {
  isShowFooterJoinForm: PropTypes.bool
};

export default Footer;
